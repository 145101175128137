import {PageTitleWrapper} from '../../../_metronic/layout/components/toolbar/page-title'

function ToolbarWrapper() {
  return (
    <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
      <div id='kt_app_toolbar_container' className='p-0 d-flex flex-stack container-fluid'>
        <PageTitleWrapper />
      </div>
    </div>
  )
}

export default ToolbarWrapper
