import React, {useEffect, useRef, useState} from 'react'
import Select from 'react-select'

export function FilterDropdown() {
  const countryOptions = [
    {value: 'all', label: 'all'},
    {value: 'saudi arabia', label: 'saudi arabia'},
    {value: 'egypt', label: 'egypt'},
  ]
  const statusOptions = [
    {value: 'all', label: 'all'},
    {value: 'active', label: 'active'},
    {value: 'inactive', label: 'inactive'},
  ]
  const jobOptions = [
    {value: 'all', label: 'all'},
    {value: 'job', label: 'job 1'},
    {value: 'job', label: 'job 2'},
  ]

  const [countryChoice, setCountryChoice] = useState('')
  const [jobChoice, setJobChoice] = useState('')
  const [statusChoice, setStatusChoice] = useState('')

  useEffect(() => {
    // console.log(countryChoice.value)
    // console.log(stateChoice.value)
    // console.log(jobChoice.value)
    // console.log(statusChoice.value)
  }, [countryChoice, jobChoice, statusChoice])
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <div className='mb-3'>
          <label className='form-label fw-bold'>country:</label>
          <div>
            <Select
              className='react-select-container'
              classNamePrefix='react-select'
              placeholder='Select country'
              name='country'
              defaultValue={countryOptions[0]}
              options={countryOptions}
              onChange={(choice) => setCountryChoice(choice)}
            />
          </div>
        </div>
        <div className='mb-3'>
          <label className='form-label fw-bold'>job:</label>
          <div>
            <Select
              className='react-select-container'
              classNamePrefix='react-select'
              placeholder='Select job'
              name='job'
              defaultValue={jobOptions[0]}
              options={jobOptions}
              onChange={(choice) => setJobChoice(choice)}
            />
          </div>
        </div>
        <div className='mb-10'>
          <label className='form-label fw-bold'>status:</label>
          <div>
            <Select
              className='react-select-container'
              classNamePrefix='react-select'
              placeholder='Select status'
              name='status'
              defaultValue={statusOptions[0]}
              options={statusOptions}
              onChange={(choice) => setStatusChoice(choice)}
            />
          </div>
        </div>

        {/* <div className='mb-10'>
          <label className='form-label fw-bold'>Member Type:</label>

          <div className='d-flex'>
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='1' />
              <span className='form-check-label'>Author</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid'>
              <input className='form-check-input' type='checkbox' value='2' defaultChecked={true} />
              <span className='form-check-label'>Customer</span>
            </label>
          </div>
        </div>

        <div className='mb-10'>
          <label className='form-label fw-bold'>Notifications:</label>

          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked={true}
            />
            <label className='form-check-label'>Enabled</label>
          </div>
        </div> */}

        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
          >
            Reset
          </button>

          <button type='button' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}
