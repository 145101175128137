import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'

function FinancialsTable(props) {
  const { items } = props
  return (
    <div className='table-responsive'>
      {/* begin::Table */}
      <table className='table table-row-dashed table-row-gray-300 align-middle gy-4'>
        {/* begin::Table head */}
        <thead>
          <tr className='bg-light'>
            <th className='min-w-100px fs-6'>#</th>
            <th className='min-w-100px text-center fs-6'>activity</th>
            <th className='min-w-100px text-center fs-6'>unit</th>
            <th className='min-w-100px text-center fs-6'>transaction type</th>
            <th className='min-w-100px text-center fs-6'>booking status</th>
            <th className='min-w-100px text-center fs-6'>date & time</th>
            <th className='min-w-100px text-center fs-6'>revenue</th>
            <th className='min-w-100px text-center fs-6'>collected amount</th>
            <th className='min-w-100px text-center fs-6'>Actions</th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {items.map((e) => {
            return (
              <tr key={e.id}>
                <td>#{e.id} </td>
                <td className='text-center fs-6'>
                  <div className='d-flex justify-content-start flex-column'>
                    <span>{e.activity_category_name || '---'}</span>
                    <span>{e.activity_name || '---'}</span>
                  </div>
                </td>
                <td className='text-center fs-6'>
                  unit name
                </td>
                <td className='text-center fs-6'>
                  <div className='d-flex justify-content-start flex-column'>
                    <span>transaction type</span>
                    <span>id:#1234</span>
                  </div>
                </td>
                <td className='text-center fs-6'>
                  <span
                    className={`badge badge-${e.status.toLowerCase() === 'coming'
                      ? 'success'
                      : e.status.toLowerCase() === 'ended'
                        ? 'primary'
                        : 'danger'
                      }`}
                  >
                    {e.status}
                  </span>
                </td>
                <td className='text-center fs-6'>
                  <div className='d-flex justify-content-start flex-column'>
                    <span className='fs-6'>{e.start_time || '---'}</span>
                    <span className='fs-6'>{e.end_time || '---'}</span>
                  </div>
                </td>
                <td className='text-center fs-6'>
                  <div className='d-flex justify-content-start flex-column'>
                    <span>
                      {e.price_per_user || '0'} <span className='fs-8'>SAR</span>
                    </span>
                    <span>{e.payment_method || 'cash'}</span>
                  </div>
                </td>
                <td className='text-center fs-6'>
                  <div className='d-flex justify-content-start flex-column'>
                    <span>
                      {e.price_per_user || '0'} <span className='fs-8'>SAR</span>
                    </span>
                    <span>{e.payment_method || 'partner'}</span>
                  </div>
                </td>

                <td className='text-center fs-6'>
                  <Link
                    to={`#`}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  >
                    <KTIcon iconName='eye' className='fs-3' />
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
        {/* end::Table body */}
      </table>
      {/* end::Table */}
    </div>
  )
}

export default FinancialsTable
