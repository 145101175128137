import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import DeleteModal from './DeleteModal'
import {useState} from 'react'
import CommentViewModal from './CommentViewModal'
import {useParams} from 'react-router-dom'
import {restoreComment} from '../../_requests'

function CommentsTable(props) {
  const {userId} = useParams()
  const {items, onComplete} = props
  const [itemID, setItemId] = useState('')
  const [comment, setComment] = useState('')
  const [userName, setUserName] = useState('')
  const [showCommentModal, setShowCommentModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const imageErrorHandler = (e) => {
    e.target.src = toAbsoluteUrl('/media/avatars/blank.png')
  }
  const openCommentModal = (userName, text) => {
    setShowCommentModal(true)
    setComment(text)
    setUserName(userName)
  }
  const openDeleteModal = (id) => {
    setShowDeleteModal(true)
    setItemId(id)
  }
  const closeCommentModal = () => setShowCommentModal(false)
  const closeDeleteModal = () => setShowDeleteModal(false)
  const completeHandler = () => {
    setShowDeleteModal(false)
    onComplete(true)
  }
  const restoreHandler = (id) => {
    restoreComment(userId, id).then((res) => {
      onComplete(true)
    })
  }
  return (
    <div className='table-responsive'>
      {/* begin::Table */}
      <table className='table table-row-dashed table-row-gray-300 align-middle gy-4'>
        {/* begin::Table head */}
        <thead>
          <tr className='bg-light'>
            <th className='min-w-100px'>comment</th>
            <th className='min-w-100px text-center'>company</th>
            <th className='min-w-100px text-center'>unit</th>
            <th className='min-w-100px text-center'>time</th>
            <th className='min-w-100px text-center'>status</th>
            <th className='min-w-100px text-center'>Actions</th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {items.map((e) => {
            return (
              <tr key={e.id}>
                <td>{e.comment || '---'}</td>
                <td className='text-center'>{e.company_name || '---'}</td>
                <td className='text-center'>{e.unit_name || '---'}</td>
                <td className='text-center'>{e.created_at || '---'}</td>
                <td className='text-center'>
                  <span
                    className={`badge badge-light-${
                      e.status === 0 ? 'dark' : e.status === 1 ? 'danger' : 'primary'
                    }`}
                  >
                    {e.status === 0 ? 'deleted' : e.status === 1 ? 'deletion request' : 'published'}
                  </span>
                  <br />
                  <span className='fs-8'>{e.deleted_at}</span>
                </td>
                <td className='text-center'>
                  <button
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                    onClick={() => openCommentModal(e.comment)}
                  >
                    <KTIcon iconName='eye' className='fs-3' />
                  </button>
                  {e.status === 0 ? (
                    <button
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      onClick={() => restoreHandler(e.id)}
                    >
                      <i className='fa-solid fa-trash-arrow-up'></i>
                    </button>
                  ) : (
                    <button
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      onClick={() => openDeleteModal(e.id)}
                    >
                      <i className='fa-solid fa-trash'></i>
                    </button>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
        {/* end::Table body */}
      </table>
      {/* end::Table */}
      {/* modal */}
      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          onHide={closeDeleteModal}
          onComplete={completeHandler}
          itemId={itemID}
        />
      )}
      {showCommentModal && (
        <CommentViewModal
          show={showCommentModal}
          onHide={closeCommentModal}
          userName={userName}
          comment={comment}
        />
      )}
    </div>
  )
}

export default CommentsTable
